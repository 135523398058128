import { inject, Injectable, signal } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  Firestore,
  limit,
  Query,
  query,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Params } from '@angular/router';
import { BlogCardData, EmbedStyleFlags } from '@shared/interface/common';
import { Rally } from '@shared/interface/rally';
import { embedDefaults } from '@shared/utils/constants';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public embedFlags = signal<EmbedStyleFlags>(embedDefaults);

  public embedColor: string = '';
  public embedFont: string = '';

  private collections = {
    homePage: 'homePage',
    blogs: 'blogs',
    nonProfitsPage: 'nonProfitsPage',
    landingPages: 'landingPages',
    rally: 'rally',
    appSettings: 'appSettings'
  };

  private fireFn: Functions = inject(Functions);

  public constructor(private firestore: Firestore) {}

  /**
   * Page related calls
   */
  public getPageSettings(pageName: string): Observable<any> {
    const docRef = doc(this.firestore, `${this.collections.appSettings}/pages`);

    return docData(docRef).pipe(
      map((d) => {
        const data: any = d;
        return data[pageName];
      })
    );
  }

  // Currently called at home page and how it works page
  public getFeaturedRallies(featureType: string, max: number = 3): Observable<Rally[]> {
    const queryRef = query(
      collection(this.firestore, this.collections.rally) as Query<Rally>,
      where(`status`, '==', 'published'),
      where(`settings.featured${featureType}`, '==', true),
      limit(max)
    );

    return collectionData<Rally>(queryRef);
  }

  // Resource page
  public getBlogs(type: string, fetchAll: boolean = false): Observable<BlogCardData[]> {
    const dynamicQuery = [where('type', '==', type)];
    if (!fetchAll) {
      dynamicQuery.push(where('published', '==', true));
    }

    const queryRef = query(collection(this.firestore, this.collections.blogs) as Query<BlogCardData>, ...dynamicQuery);

    return collectionData<BlogCardData>(queryRef, { idField: 'documentId' });
  }

  // Currently called at home page and how it works page
  public getFeaturedBlogs(featureType: string): Observable<BlogCardData[]> {
    const queryRef = query(
      collection(this.firestore, this.collections.blogs) as Query<BlogCardData>,
      where(`published`, '==', true),
      where(`settings.featured${featureType}`, '==', true)
    );

    return collectionData<BlogCardData>(queryRef);
  }

  public getBlog(docId: string): Observable<any> {
    const blogRef = doc(this.firestore, this.collections.blogs, docId);

    return docData(blogRef, { idField: 'documentId' });
  }

  public getBlogBySlug(slug: string): Observable<any> {
    const collectionRef = query(collection(this.firestore, this.collections.blogs), where(`slug`, '==', slug), limit(1), where('published', '==', true));

    return collectionData(collectionRef).pipe(switchMap(async (data) => data[0]));
  }

  public updateBlog(docId: string, blog: Partial<BlogCardData>): Promise<void> {
    const docRef = doc(this.firestore, this.collections.blogs, docId);
    return updateDoc(docRef, { ...blog });
  }

  public createBlogs(blog: BlogCardData): Promise<DocumentReference<any>> {
    const collectionRef = collection(this.firestore, this.collections.blogs);
    return addDoc(collectionRef, blog);
  }

  public deleteBlog(id: string): Promise<void> {
    const blogRef = doc(this.firestore, this.collections.blogs, id);

    return deleteDoc(blogRef);
  }

  // Called by the stand alone landing pages (start a boycott, petition and contact congress)
  public getFeaturedRally(featureType: string): Observable<Rally> {
    const collectionRef = query(
      collection(this.firestore, this.collections.rally),
      where(`settings.${featureType}`, '==', true),
      limit(1),
      where('status', '==', 'published')
    );

    return collectionData(collectionRef).pipe(switchMap(async (data) => data[0] as Rally));
  }

  public getOS(): string | null {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  public async setGoogleFontLoader(): Promise<any> {
    // inject the core first
    return this.injectScript('https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
  }

  public getRallyBotScript(
    issue: string,
    prompt: string
  ): Observable<{ emailResponse: string; callResponse: string; tweetResponse: string; category: string }> {
    return httpsCallableData<{ issue: string; prompt: string }, { emailResponse: string; callResponse: string; tweetResponse: string; category: string }>(
      this.fireFn,
      'aiScriptTest'
    )({ issue, prompt });
  }

  public setEmbedFlags(params: Params) {
    const { summary, images, allTargets, takeMoreActions, font, fontColor, manualWidth, width, height, embedurl } = params;
    this.embedFlags.set({
      showSummary: summary && summary === 'false' ? false : true,
      showImage: images && images === 'false' ? false : true,
      showAllActions: allTargets && allTargets === 'false' ? false : true,
      takeMoreActions: takeMoreActions && takeMoreActions === 'true' ? true : false,
      fontFamily: font || '',
      fontColor: fontColor || '',
      manualWidth: manualWidth && manualWidth === 'true' ? true : false,
      width: width ? parseInt(width, 10) : 0,
      height: height ? parseInt(height, 10) : 0,
      embedurl: embedurl || ''
    });

    this.embedColor = fontColor || '';
    this.embedFont = font || '';
  }

  private injectScript(src: string): Promise<any> {
    if (this.isScriptLoaded(src)) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.src = src;
      script.addEventListener('load', resolve);
      script.addEventListener('error', (e) => reject(e.error));
      document.head.appendChild(script);
    });

    return Promise.resolve(true);
  }

  private isScriptLoaded(url: string): boolean {
    const scripts = document.getElementsByTagName('script');

    for (let i = scripts.length; i--; ) {
      if (scripts[i].src === url) {
        return true;
      }
    }

    return false;
  }
}
