import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import {
  addDoc,
  and,
  collection,
  collectionData,
  collectionGroup,
  collectionSnapshots,
  deleteDoc,
  doc,
  docData,
  DocumentData,
  DocumentReference,
  Firestore,
  getAggregateFromServer,
  getCountFromServer,
  getDocs,
  or,
  orderBy,
  query,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  serverTimestamp,
  sum,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { MessagePayload, RallyCreatorMessagePayload } from '@shared/interface/common';
import { ActionsTaken, CreateRallyTarget, Rally, RallyTarget, RallyUpdate, UserSubscription } from '@shared/interface/rally';
import { Representative } from '@shared/interface/user';
import { RallyTypes } from '@shared/utils/constants';
import { ChatCompletionResponseMessage, CreateChatCompletionResponse } from 'openai';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RallyService {
  public rallyBotData: WritableSignal<{
    name: string;
    targets: RallyTarget[];
    outcome: string;
    description: string;
    email: string;
    call: string;
    tweet: string;
    title: string;
  }> = signal({
    name: '',
    targets: [],
    outcome: '',
    description: '',
    email: '',
    call: '',
    tweet: '',
    title: ''
  });

  private collections = {
    rally: 'rally',
    target: 'targets',
    groupTarget: 'groupTarget',
    actionsTaken: 'actionsTaken',
    actionsTakenReport: 'actionsTakenReport',
    subscription: 'subscription',
    petition: 'petition',
    rallyUpdates: 'rallyUpdates'
  };

  private analytics = inject(Analytics);
  private firestore: Firestore = inject(Firestore);
  private userService: UserService = inject(UserService);
  private fireFn: Functions = inject(Functions);
  private authService: AuthService = inject(AuthService);

  public constructor() {}

  public createRallyTarget(rallyTarget: CreateRallyTarget): Promise<DocumentReference<DocumentData>> {
    const collectionRef = collection(this.firestore, this.collections.target);
    return addDoc(collectionRef, rallyTarget);
  }

  public createRallyGroupTarget(rallyGroupTarget: CreateRallyTarget): Observable<any> {
    return httpsCallableData(this.fireFn, 'createRallyGroupTarget')({ rallyGroupTarget });
  }

  public createRally(rally: Partial<Rally>): Promise<DocumentReference<DocumentData>> {
    const collectionRef = collection(this.firestore, this.collections.rally);
    return addDoc(collectionRef, rally);
  }

  public updateRally(docId: string, rally: Partial<Rally>): Promise<void> {
    const docRef = doc(this.firestore, this.collections.rally, docId);
    return updateDoc(docRef, { ...rally, updatedAt: serverTimestamp() });
  }

  public getRally(docId: string): Observable<Rally | undefined> {
    const docRef = doc(this.firestore, this.collections.rally, docId) as DocumentReference<Rally>;
    return docData<Rally>(docRef, { idField: 'documentId' });
  }

  public getRallyByIDandSlug(id: number, slug?: string, owner: boolean = false): Promise<QuerySnapshot<Rally>> {
    let dynamicQuery = and(where('status', '==', 'published'), or(where('slug', '==', slug), where('id', '==', id)));

    if (owner && this.authService.currentUserUID) {
      dynamicQuery = and(or(where('slug', '==', slug), where('id', '==', id)), where('roles.owners', 'array-contains', this.authService.currentUserUID));
    }

    const queryRef = query(collection(this.firestore, this.collections.rally) as Query<Rally>, dynamicQuery);

    return getDocs(queryRef);
  }

  public getRallyByID(id: number): Promise<QuerySnapshot<Rally>> {
    const dynamicQuery = and(where('status', '==', 'published'), where('id', '==', id));

    const queryRef = query(collection(this.firestore, this.collections.rally) as Query<Rally>, dynamicQuery);

    return getDocs(queryRef);
  }

  public getRallyByIDandSlugAdmin(id: number, slug?: string): Promise<QuerySnapshot<Rally>> {
    const dynamicQuery = [];

    if (slug) {
      dynamicQuery.push(where('slug', '==', slug));
    }

    const queryRef = query(collection(this.firestore, this.collections.rally) as Query<Rally>, where('id', '==', id), ...dynamicQuery);

    return getDocs(queryRef);
  }

  public getRallies(filterFn: QueryConstraint[] = []): Observable<Rally[]> {
    const queryRef = query(collection(this.firestore, this.collections.rally) as Query<Rally>, ...filterFn);
    return collectionData(queryRef, { idField: 'documentId' });
  }

  public getActionsTaken(docId: string, userId: string | null): Observable<ActionsTaken[]> {
    if (!userId) {
      return of([]);
    }

    const collectionRef = query(
      collection(this.firestore, this.collections.rally, docId, this.collections.actionsTaken) as Query<ActionsTaken>,
      where('userId', '==', userId)
    );

    return collectionData(collectionRef, { idField: 'documentId' });
  }

  /**
   * This is the refactored version that doesn't trigger when firestore data changes
   *
   * @param docId
   * @param userId
   * @returns
   */
  public getActionsTakenByUser(docId: string, userId: string | null): Promise<QuerySnapshot<ActionsTaken>> {
    const collectionRef = query(
      collection(this.firestore, this.collections.rally, docId, this.collections.actionsTaken) as Query<ActionsTaken>,
      where('userId', '==', userId)
    );

    return getDocs(collectionRef);
  }

  /**
   * This is the refactored version that doesn't trigger when firestore data changes
   *
   * @param docId
   * @param userId
   * @returns
   */
  public getSubscriptionsByUser(docId: string, userId: string | null): Promise<QuerySnapshot<UserSubscription>> {
    const collectionRef = query(
      collection(this.firestore, this.collections.rally, docId, this.collections.subscription) as Query<UserSubscription>,
      where('userId', '==', userId)
    );

    return getDocs(collectionRef);
  }

  public getActionsTakenReport(docId: string): Observable<any> {
    const collectionRef = collection(this.firestore, this.collections.rally, docId, this.collections.actionsTakenReport);

    return collectionData(collectionRef) as Observable<any>;
  }

  public createActionTaken(docId: string, actionTaken: ActionsTaken, rally: Rally, name: string, email: string): Promise<DocumentReference<unknown>> {
    // Log to Google Analytics
    if (this.analytics) {
      email = email || this.authService.currentUser?.email || this.authService.unauthenticatedUserCache.email || '';
      name =
        name ||
        this.authService.currentUser?.name ||
        `${this.authService.unauthenticatedUserCache.firstName} ${this.authService.unauthenticatedUserCache.lastName}`;

      logEvent(this.analytics, `Action Takers - Action Taken - ${actionTaken.type}`, {
        rally_name: rally.name,
        action_type: actionTaken.type,
        user_id: this.authService.currentUserUID || '',
        first_name: name?.split(' ')[0] || '',
        last_name: name?.split(' ').splice(1).join(' ') || '',
        email: email || '',
        url: `${environment.siteURL}/rally/${rally.id}/${rally.slug}`
      });
    }

    // if the userId is empty then we are storing unauthenticated user actions data in localstorage
    if (actionTaken.userId === '') {
      this.userService.storeActionsToSession(docId, actionTaken);
    }

    const collectionRef = collection(this.firestore, this.collections.rally, docId, this.collections.actionsTaken);
    return addDoc(collectionRef, actionTaken);
  }

  public getUserSubscriptions(rallyID: string, userId: string | null): Observable<UserSubscription[]> {
    if (!userId) {
      return of([]);
    }

    const collectionRef = query(
      collection(this.firestore, this.collections.rally, rallyID, this.collections.subscription) as Query<UserSubscription>,
      where('userId', '==', userId)
    );

    return collectionData(collectionRef, { idField: 'documentId' }) as Observable<UserSubscription[]>;
  }

  public updateSubscription(rallyID: string, subID: string, subData: Partial<UserSubscription>): Promise<void> {
    const docRef = doc(this.firestore, this.collections.rally, rallyID, this.collections.subscription, subID);
    return updateDoc(docRef, { ...subData, updatedAt: serverTimestamp() });
  }

  public async deleteSubscription(rallyID: string, userId: string): Promise<void> {
    // Log to Google Analytics
    if (this.analytics) {
      const email = this.authService.currentUser?.email || this.authService.unauthenticatedUserCache.email || '';
      const name: string =
        this.authService.currentUser?.name || `${this.authService.unauthenticatedUserCache.firstName} ${this.authService.unauthenticatedUserCache.lastName}`;

      logEvent(this.analytics, 'Action Takers - Unsubscribe', {
        action: 'Unsubscribe',
        user_id: this.authService.currentUserUID || '',
        email,
        first_name: name?.split(' ')[0] || '',
        last_name: name?.split(' ').splice(1).join(' ') || ''
      });
    }

    // Get all actions taken by the user and update delete value to true
    const actionsTakenRef = collection(this.firestore, this.collections.rally, rallyID, this.collections.actionsTaken);
    const actionsTakenQueryRef = query(actionsTakenRef, where('userId', '==', userId));
    const actionsTakenSnapshot = await getDocs(actionsTakenQueryRef);
    actionsTakenSnapshot.forEach((action) => {
      updateDoc(action.ref, { userId: `${userId}-deleted` });
    });

    // Get all subscriptions and delete them
    const subscriptionRef = collection(this.firestore, this.collections.rally, rallyID, this.collections.subscription);
    const subscriptionQueryRef = query(subscriptionRef, where('userId', '==', userId));
    const subscriptionSnapshot = await getDocs(subscriptionQueryRef);
    subscriptionSnapshot.forEach((sub) => {
      deleteDoc(sub.ref);
    });

    return Promise.resolve();
  }

  public async getRallySubscriptionCount(docId: string): Promise<number> {
    const collectionRef = collection(this.firestore, this.collections.rally, docId, this.collections.subscription);
    const snapshot = await getCountFromServer(collectionRef);
    return snapshot.data().count;
  }

  public subscribeToCampaign(rally: Rally, subData: UserSubscription): Promise<DocumentReference<unknown>> {
    // Log to Google Analytics
    if (this.analytics) {
      const email = this.authService.currentUser?.email || this.authService.unauthenticatedUserCache.email || '';
      const name: string =
        this.authService.currentUser?.name || `${this.authService.unauthenticatedUserCache.firstName} ${this.authService.unauthenticatedUserCache.lastName}`;

      logEvent(this.analytics, 'Action Takers - Subscribe', {
        rally_name: rally.name,
        action_taken: 'Subscribe',
        user_id: this.authService.currentUserUID || '',
        email,
        first_name: name?.split(' ')[0] || '',
        last_name: name?.split(' ').splice(1).join(' ') || '',
        url: `${environment.siteURL}/rally/${rally.id}/${rally.slug}`
      });
    }

    // if the userId is empty then we are storing unauthenticated user subscription data in localstorage
    if (subData.userId === '') {
      this.userService.storeSubscriptionToSession(rally.objectID, subData, 'subscriptions');
    }

    const collectionRef = collection(this.firestore, this.collections.rally, rally.objectID, this.collections.subscription);

    return addDoc(collectionRef, subData);
  }

  public getUserJoinedRallies(filterFn: QueryConstraint[] = []): Observable<QueryDocumentSnapshot<ActionsTaken>[]> {
    const collectionRef = query(collectionGroup(this.firestore, this.collections.actionsTaken), ...filterFn);
    return collectionSnapshots(collectionRef) as Observable<QueryDocumentSnapshot<ActionsTaken>[]>;
  }

  // TODO: remove this and properly implement the send message to government reps
  public sendGovernmentMessage(recipients: Representative[], rally: Rally): Observable<Response> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return httpsCallableData<{ Recipients: Representative[]; rally: Rally }, Response>(
      this.fireFn,
      'testCall'
      // eslint-disable-next-line @typescript-eslint/naming-convention
    )({ Recipients: recipients, rally });
  }

  public sendToSenate(payload: MessagePayload): Observable<Response> {
    return httpsCallableData<MessagePayload, Response>(this.fireFn, 'sendSenateMessage')(payload);
  }

  public sendToCongress(payload: MessagePayload): Observable<Response> {
    return httpsCallableData<MessagePayload, Response>(this.fireFn, 'sendHouseMessage')(payload);
  }

  public getRallySupporters(docId: string): Observable<UserSubscription[]> {
    return httpsCallableData<{ rallyId: string }, UserSubscription[]>(this.fireFn, 'downloadRallySupporters')({ rallyId: docId });
  }

  public getRallyUpdates(docId: string): Observable<RallyUpdate[]> {
    const collectionRef = collection(this.firestore, this.collections.rally, docId, this.collections.rallyUpdates);
    const queryRef = query(collectionRef, orderBy('createdAt', 'desc'));
    return collectionData(queryRef, { idField: 'documentId' }) as Observable<RallyUpdate[]>;
  }

  public sendUpdateToSupporters(rally: RallyUpdate): Promise<DocumentReference<unknown>> {
    const collectionRef = collection(this.firestore, this.collections.rally, rally.rallyId, this.collections.rallyUpdates);
    return addDoc(collectionRef, rally);
  }

  public resyncRalliesToAlgolia(): Observable<{ code: number }> {
    return httpsCallableData<undefined, { code: number }>(this.fireFn, 'resyncRalliesToAlgolia')();
  }

  public sendRallyCreatorMessage(payload: RallyCreatorMessagePayload): Observable<Response> {
    return httpsCallableData<RallyCreatorMessagePayload, Response>(this.fireFn, 'sendRallyCreatorMessage')(payload);
  }

  public generateRallyDescription(name: string, type: RallyTypes): Observable<CreateChatCompletionResponse> {
    return httpsCallableData<{ name: string; type: RallyTypes }, CreateChatCompletionResponse>(this.fireFn, 'generateRallyDescription')({ name, type });
  }

  public rewriteRallyDescription(
    name: string,
    type: RallyTypes,
    description: string,
    messages?: ChatCompletionResponseMessage
  ): Observable<CreateChatCompletionResponse> {
    return httpsCallableData<{ name: string; type: RallyTypes; description: string; messages?: ChatCompletionResponseMessage }, CreateChatCompletionResponse>(
      this.fireFn,
      'rewriteRallyDescription'
    )({
      name,
      type,
      description,
      messages
    });
  }

  public generateRallyScript(name: string, description: string, type: string, targets: RallyTarget[]): Observable<CreateChatCompletionResponse> {
    return httpsCallableData<{ name: string; description: string; type: string; targets: RallyTarget[] }, CreateChatCompletionResponse>(
      this.fireFn,
      'generateRallyScript'
    )({
      name,
      type,
      description,
      targets
    });
  }

  public generateStrategy(name: string, type: RallyTypes, description: string): Observable<string> {
    return httpsCallableData<{ name: string; type: RallyTypes; description: string }, string>(
      this.fireFn,
      'generateStrategy'
    )({
      name,
      type,
      description
    });
  }

  public generateRally(
    name: string,
    targets: RallyTarget[],
    outcome: string
  ): Observable<{ summary: string; title: string; rallyEmail: string; rallyCall: string; rallyTweet: string }> {
    return httpsCallableData<
      { name: string; targets: RallyTarget[]; outcome: string },
      { summary: string; title: string; rallyEmail: string; rallyCall: string; rallyTweet: string }
    >(
      this.fireFn,
      'generateRally'
    )({
      name,
      targets,
      outcome
    });
  }

  public generateCongressMessage(issue: string): Observable<{ emailResponse: string; callResponse: string; tweetResponse: string; category: string }> {
    return httpsCallableData<{ issue: string }, { emailResponse: string; callResponse: string; tweetResponse: string; category: string }>(
      this.fireFn,
      'generateCongressMessage'
    )({ issue });
  }

  public async getRallyCount(): Promise<number> {
    const collectionRef = query(collection(this.firestore, this.collections.rally), where('status', '==', 'published'));
    const snapshot = await getCountFromServer(collectionRef);
    return snapshot.data().count;
  }

  public async getActionCount(): Promise<number> {
    const collectionRef = query(collection(this.firestore, this.collections.rally), where('status', '==', 'published'));
    const snapshot = await getAggregateFromServer(collectionRef, {
      totalActions: sum('totalActions')
    });
    return snapshot.data().totalActions;
  }
}
