import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { UserCredential } from '@angular/fire/auth';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { MenuItems } from '@shared/interface/common';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { filter, Observable, tap } from 'rxjs';

import { LoginModalComponent } from '../login-modal/login-modal.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, NgClass, NgFor, AvatarModule, MenuModule, AsyncPipe]
})
export class HeaderComponent implements OnInit {
  @Input() public data: any;

  public user: Observable<any>;
  public isAdmin: Observable<boolean>;
  public theme: 'light' | 'dark' = 'dark';
  public position: 'absolute' | 'fixed' | 'relative' = 'relative';
  public positionDefault: 'absolute' | 'fixed' | 'relative' = 'relative';
  public border: boolean = false;
  public rallybot: boolean = false;
  public publicMenuItems: MenuItems[] = [
    {
      name: 'Explore Campaigns',
      path: '/explore-campaigns',
      class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
      parentClass: 'flex'
    },
    {
      name: 'Free Campaign Tools',
      path: '/how-it-works',
      class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
      parentClass: 'flex'
    },
    {
      name: 'Advocacy Resources',
      path: '/resources',
      class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
      parentClass: 'flex'
    }
  ];
  public menuItem: MenuItems[] = this.publicMenuItems;

  public loggedInMenu: MenuItems[] = [
    {
      name: 'Profile',
      path: '/dashboard',
      class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
      parentClass: 'flex md:hidden'
    },
    {
      name: 'My Rallies',
      path: '/dashboard',
      class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
      parentClass: 'flex md:hidden',
      queryParams: { tab: 'my-rallies' }
    },
    {
      name: 'Joined Rallies',
      path: '/dashboard',
      class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
      parentClass: 'flex md:hidden',
      queryParams: { tab: 'joined-rallies' }
    },
    {
      name: 'Logout',
      path: '/',
      class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
      parentClass: 'flex md:hidden',
      clickFn: () => {
        this.onLogout();
        this.menuShow = false;
      }
    }
  ];

  public secondMenuItem: MenuItems[] = [
    {
      name: 'Start a Campaign',
      path: '/rally/create',
      class: 'rs-button second-btn border-3 font-semibold align-middle border-solid no-underline text-rs-black cursor-pointer relative',
      parentClass: 'flex',
      clickFn: () => {
        if (this.analytics) {
          logEvent(this.analytics, 'Start Campaign Clicked - Header', {
            type: 'Header',
            user: this.authService.currentUserUID || '',
            email: this.authService.currentUser?.email || ''
          });
        }
      }
    }
  ];

  public accountMenuItems: MenuItem[] = [
    {
      label: 'Profile',
      routerLink: '/dashboard'
    },
    {
      label: 'My Rallies',
      routerLink: '/dashboard',
      queryParams: { tab: 'my-rallies' }
    },
    {
      label: 'Joined Rallies',
      routerLink: '/dashboard',
      queryParams: { tab: 'joined-rallies' }
    },
    {
      label: 'Logout',
      command: () => {
        this.onLogout();
      }
    }
  ];

  public menuShow: boolean = false;

  public authService: AuthService = inject(AuthService);
  private analytics = inject(Analytics);
  private dialog: DialogService = inject(DialogService);
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);
  public constructor() {}

  public toggleNavbar() {
    this.menuShow = !this.menuShow;
    this.position = this.menuShow ? 'fixed' : this.positionDefault;
  }

  public ngOnInit(): void {
    this.authService.hasAdminAccess();
    this.user = this.authService.currentAuthStatus.pipe(
      tap((user: UserCredential) => {
        if (user) {
          this.menuItem = this.rallybot
            ? this.publicMenuItems.concat(this.loggedInMenu)
            : this.publicMenuItems
                .slice(0, this.publicMenuItems.length - 1)
                .concat(this.loggedInMenu)
                .concat(this.publicMenuItems[this.publicMenuItems.length - 1]);
          // add the profile and logout links
        } else {
          this.menuItem = this.publicMenuItems;
        }
      })
    );
    this.isAdmin = this.authService.isAdmin;
    // If user is admin, add the admin link to accountMenu items as the second to last
    this.isAdmin.pipe(untilDestroyed(this)).subscribe((isAdmin) => {
      if (isAdmin) {
        this.accountMenuItems.splice(this.accountMenuItems.length - 1, 0, {
          label: 'Admin',
          routerLink: '/admin'
        });
      }
    });
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const data = this.route.snapshot.firstChild?.firstChild?.data;
      if (data) {
        this.theme = data.theme ? data.theme : 'dark';
        this.position = data.position ? data.position : 'relative';
        this.positionDefault = data.position ? data.position : 'relative';
        this.border = data.border ? data.border : false;
        this.rallybot = data.rallybot ? data.rallybot : false;

        if (this.rallybot) {
          this.publicMenuItems = [
            {
              name: 'Rallybot AI Tools',
              class: 'hover-underline-animation no-underline text-white cursor-pointer relative hidden md:flex',
              parentClass: 'hidden md:flex',
              children: [
                {
                  label: 'Rallybot AI',
                  routerLink: '/rallybot',
                  parentClass: 'hidden md:flex'
                },
                {
                  label: 'Contact Congress',
                  routerLink: '/rallybot/contact-congress',
                  parentClass: 'hidden md:flex'
                }
              ]
            },
            {
              name: 'Rallybot AI',
              class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
              parentClass: 'flex md:hidden'
            },
            {
              name: 'Contact Congress',
              class: 'hover-underline-animation no-underline text-white cursor-pointer relative',
              parentClass: 'flex md:hidden'
            }
          ];
        }
      }
    });
  }

  public get logoURL(): string {
    // Theming doesn't seem to be used right now. So using the pages as the indicator of which logo to use
    // landing page, how-it-works and for-nonprofits uses the "light" logo
    return `assets/img/logo-${this.theme}.svg`;
  }

  public get menuItems(): any[] {
    return this.currentPath === '/start-a-boycott' || this.currentPath === '/start-a-petition' ? this.secondMenuItem : this.menuItem;
  }

  public get currentPath(): string {
    return this.router.url;
  }

  public onLogin(): void {
    this.dialog.open(LoginModalComponent, {
      header: 'Login'
    });
  }

  public onLogout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['']);
      location.reload();
    });
  }
}
