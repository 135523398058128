<footer class="pb-4 globalFooter block">
  <div class="container mx-auto">
    <div class="flex flex-col gap-4 md:gap-14 sm:flex-row">
      <div class="flex-none">
        <a class="cursor-pointer no-underline" href="/">
          <img style="width: 64px" alt="Rallystarter" title="Rally Starter" src="assets/img/green-no-text.svg" width="64" height="74" />
        </a>
      </div>
      <div class="flex flex-col md:grid grid-cols-5 gap-x-4 gap-y-0 sm:gap-y-4">
        <div>
          <span class="font-bold">Get Started</span>
          <ul>
            <li>
              <a routerLink="/explore-campaigns" class="hover-underline cursor-pointer no-underline" title="Explore Campaigns">Explore Campaigns</a>
            </li>
            <li>
              <a routerLink="/rally/create" class="hover-underline cursor-pointer no-underline" title="Launch a Campaign">Launch a Campaign</a>
            </li>
            <li>
              <a routerLink="/advocacy-software-for-nonprofits" class="hover-underline cursor-pointer no-underline" title="For Nonprofits &amp; Organizers"
                >For Nonprofits &amp; Organizers</a
              >
            </li>
            <li class="sm:hidden block">
              <a routerLink="/about-us" class="hover-underline cursor-pointer no-underline" title="About Us">About Us</a>
            </li>
            <li class="sm:hidden block">
              <a routerLink="/contact" class="hover-underline cursor-pointer no-underline" title="Contact">Contact</a>
            </li>
            <!-- <li>
              <a routerLink="/nonprofit-services" class="hover-underline cursor-pointer no-underline" title="Nonprofit Services">Nonprofit Services</a>
            </li> -->
          </ul>
        </div>
        <div>
          <span class="font-bold">AI Tools</span>
          <ul>
            <li>
              <a routerLink="/rallybot" class="hover-underline cursor-pointer no-underline" title="Campaign Builder">Campaign Builder</a>
            </li>
            <li>
              <a routerLink="/rallybot/contact-congress" class="hover-underline cursor-pointer no-underline" title="Contact Congress">Contact Congress</a>
            </li>
          </ul>
        </div>
        <div>
          <span class="font-bold">Resources</span>
          <ul>
            <li>
              <a routerLink="/about-us" class="hover-underline cursor-pointer no-underline" title="Our Story">Our Story</a>
            </li>
            <li>
              <a routerLink="/how-it-works" class="hover-underline cursor-pointer no-underline" title="Free Campaign Tools">Free Campaign Tools</a>
            </li>
            <li>
              <a routerLink="/resources" class="hover-underline cursor-pointer no-underline" title="Advocacy Resources">Advocacy Resources</a>
            </li>
            <li>
              <a routerLink="/rally-guidelines" class="hover-underline cursor-pointer no-underline" title="Rally Guidelines">Rally Guidelines</a>
            </li>
          </ul>
        </div>
        <div class="col-span-2 sm:col-span-1 sm:block hidden">
          <span class="font-bold">Support & Policies</span>
          <ul class="grid grid-cols-2 sm:grid-none sm:block gap-x-4">
            <li>
              <a routerLink="/faqs" class="hover-underline cursor-pointer no-underline" title="FAQs">FAQs</a>
            </li>
            <li>
              <a routerLink="/policies" class="hover-underline cursor-pointer no-underline" title="Policies">Policies</a>
            </li>
            <li>
              <a routerLink="/contact" class="hover-underline cursor-pointer no-underline" title="Contact">Contact</a>
            </li>
          </ul>
        </div>
        <div class="col-span-2 sm:col-span-3 md:col-span-3 lg:col-span-1">
          <div class="text-center lg:text-right footer-social flex-grow-2">
            <div class="mb-12">
              <a routerLink="/rally/create" class="rs-button clear-teal">Create a campaign</a>
            </div>
            <div class="social-holder">
              <a
                class="bg-twitter social-circle m-05 cursor-pointer no-underline"
                target="_blank"
                href="https://twitter.com/rallystarters"
                title="https://twitter.com/rallystarters">
                <div class="x-twitter w-4 h-4"></div>
              </a>
              <a
                class="bg-facebook social-circle m-05 cursor-pointer no-underline"
                target="_blank"
                href="https://www.facebook.com/rallystarter"
                title="https://www.facebook.com/rallystarter">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a
                class="bg-linkedin social-circle m-05 cursor-pointer no-underline"
                target="_blank"
                href="https://www.linkedin.com/company/56437650"
                title="https://www.linkedin.com/company/56437650">
                <i class="fa-brands fa-linkedin-in"></i>
              </a>
              <a
                class="bg-instagram social-circle m-05 cursor-pointer no-underline"
                target="_blank"
                href="https://www.instagram.com/rallystarter/"
                title="https://www.instagram.com/rallystarter/">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
